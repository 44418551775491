import React from 'react';
import Head from 'next/head';

interface Props {
  title: string;
}

const PageTitle: React.FC<Props> = ({ title }) => {
  const appName = 'Aquinna Homes';
  const finalTitle = title ? `${appName} - ${title}` : appName;
  return (
    <Head>
      <title>{finalTitle}</title>
    </Head>
  );
};

export default PageTitle;
