import axios from 'axios';

// eslint-disable-next-line
const api = process.browser
  ? // eslint-disable-next-line
    process.env.NEXT_PUBLIC_API_URL || '/api'
  : // eslint-disable-next-line
    process.env.BUILD_API_URL || 'http://localhost:3000/api';
/**
 * Get request to api
 */
function get(url) {
  return axios.get(api + url).then(res => res.data);
}

/**
 * Post request to api
 * @param {string} url The url to post to
 * @param {Object} JSON object to post
 */
function post(url, data) {
  return axios.post(api + url, data).then(res => res.data);
}

export { get, post };
