import React, { useState } from 'react';
import cookie from 'react-cookie';
import { post } from '../components/common/fetch';
import PageTitle from '../components/common/PageTitle';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();

  const submit = (e) => {
    e.preventDefault();
    post('/auth/login', { username, password })
      .then((result) => {
        cookie.save('aquinna', result.token, { path: '/' });
        window.location.href = '/admin';
      })
      .catch((err) => setError(err.message));
  };

  return (
    <section className="login">
      <PageTitle title="Login" />
      <div className="container">
        <form className="col-md-4 col-md-offset-4" onSubmit={submit}>
          <h2 className="form-signin-heading">Login</h2>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="form-group">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="form-control"
              placeholder="Username"
              autoComplete="username"
              required
              autoFocus // eslint-disable-line
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="Password"
              autoComplete="current-password"
              required
              style={{ marginTop: 5 }}
            />
          </div>
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Login
          </button>
        </form>
      </div>
    </section>
  );
};

export default Login;
